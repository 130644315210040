// Here you can add other styles
.loadig {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 999;
}

.alert {
  border-radius: 0;
  margin-bottom: 0;
}

button {
  &:focus {
    outline: 0;
  }
}

.table td {
  vertical-align: middle;
}
